@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic,800italic,600italic,600&subset=latin,latin-ext);
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,400i,500,600,700,800,900');
@images:    "../images";

@color-primary:             #f9f1eb;
@color-gray:                #858585;
@color-white:               #ffffff;
@color-black:               #000000;
@color-background:          #f9f1eb;

@font-family:          'Poppins', sans-serif;

html,
body {
    width: 100%;
    height: 100%;
    background-color: @color-background;
    font-family: @font-family;
    font-size: 14px;

}

.m0 { margin: 0px; }
.p0 { padding: 0px; }

.mt10 { margin-top: 10px !important; }
.mt20 { margin-top: 20px !important; }
.mt30 { margin-top: 30px !important; }
.mt35 { margin-top: 35px !important; }
.mt40 { margin-top: 40px !important; }
.mt45 { margin-top: 45px !important; }
.mt50 { margin-top: 50px !important; }
.mt60 { margin-top: 60px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb40 { margin-bottom: 40px !important; }
.mb50 { margin-bottom: 50px !important; }
.mb60 { margin-bottom: 60px !important; }

.fw500 { font-weight: 500; }
.fw700 { font-weight: 700; }

.f14 { font-size: 14px !important; }
.f16 { font-size: 16px !important; }
.f21 { font-size: 21px !important; }

.c-white { color: @color-white!important; }

.bg-2 { background: #f2f3f5; }

input:focus,
select:focus {
    outline: none;
}

input,
button {
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
}

button:focus {
    outline: none;
}

button {
    cursor: pointer;
}

:before,
:after {
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
}

a {
    font-size: 14px;
    color: #4a4846;
    text-decoration: none;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    &:hover,
    &:focus {
        color: @color-black;
    }
}

a:active,
a:hover,
a:focus {
    outline: none;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

p {
    color: #4a4846;
    font-size: 16px;
    font-weight: 300;
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

#container {
    overflow: hidden;
}

header {
    background: @color-white;
}



a#scrollUp {
    position: absolute;
    right: 30px;
    bottom: 30px;
    height: 60px;
    width: 60px;
    background: @color-primary;
    border: 1px solid #4a4846;
    text-align: center;
    line-height: 60px;
    color: #4a4846;
    &:hover {
        background: darken(@color-primary, 3%);
    }
}

.navbar-toggler {
    color: @color-black;
    border-color: @color-black
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}




.navbar {
    padding: 50px 0;
    border-bottom: 1px solid #e2e2e2;
    background: @color-primary;
}
.navbar-brand {
    padding: 0px;
}
.navbar-brand img {
    width: 250px;
}
@media (max-width: 991px) {
    .navbar {
        padding: 35px 0;
    }
    .navbar-brand img {
        width: 200px;
    }
    .navbar-brand--centered {
        position: relative;
        left: calc(~'50% - 100px');
    }
}


.navbar-nav .nav-link {
    color: #000 !important;
    background: transparent;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
}
.navbar-nav .nav-item span {
    font-size: 12px;
    font-style: italic;
    display: block;
    text-align: center;
    text-transform: initial;
}
.dropdown-toggle::after {
    display: none;
}
.top-navbar {
    background: #fff;
    border-bottom: 1px solid #f1f1f1;
    position: relative;
    z-index: 500;
}

@media (max-width: 991px) {

    .navbar-nav .nav-link {
        color: #666;
        padding: 0.875rem 1rem;
        margin-right: 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        text-align: center;
    }

    .navbar-nav .dropdown-menu .dropdown-item {
        padding: 0.75rem 2rem;
        line-height: 1.42857143;
        border: 0px;
    }
}



.dropdown-menu {
    border: 0px;
    border-radius: 0!important;
    background-color: @color-white!important;
    min-width: 14rem!important;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    .dropdown-item {
        padding: 0.5rem 1.5rem!important;

        &:focus, 
        &:hover {
            background-color: @color-primary!important;
            color: @color-black!important;
            font-weight: 500;
            text-indent: 5px;
        }
    }
}
@media (max-width: 991px) {

    .dropdown-menu {
        box-shadow: none;
        margin: 0px;
        text-align: center;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }

}






.slice {
    padding-top: 65px;
    padding-bottom: 65px;
    position: relative;
}
.sct-color-1 {
    background-color: @color-primary;
}

.bg-size-cover {
    background-size: cover;
    background-repeat: no-repeat;
}
.has-bg-cover {
    position: relative;
}
.slice-lg {
    padding-top: 6rem;
    padding-bottom: 6rem;
    position: relative;
    min-height: 295px;
}
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.mask-dark--style-2 {
    background: rgba(0,0,0,0.4);
}
.fluid-paragraph {
    font-size: 1rem;
    width: 680px;
    margin: auto;
    padding: 0 20px;
    position: relative;
}
@media (max-width: 767px) {
    .fluid-paragraph {
        width: 100% !important;
    }
}


.block {
    margin: 0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: relative;
    cursor: default;
}
.block .block-image {
    position: relative;
    background-position: 0 0;
    background-size: cover;
    overflow: hidden;
    -webkit-backface-visibility: hidden;

}
.block .block-image img {
    max-width: 100%;
    z-index: 3;
    -webkit-transition: -webkit-transform 0.8s ease-out;
    -moz-transition: -moz-transform 0.8s ease-out;
    -ms-transition: -ms-transform 0.8s ease-out;
    -o-transition: -o-transform 0.8s ease-out;
    transition: transform 0.8s ease-out;

}
.block .block-image a {
    display: block;
}

.block .block-image&:hover {
    background-position: 0 263px;

    img {
        -webkit-transform: scale(1.3, 1.3) rotate(3deg);
        -moz-transform: scale(1.3, 1.3) rotate(3deg);
        -ms-transform: scale(1.3, 1.3) rotate(3deg);
        -o-transform: scale(1.3, 1.3) rotate(3deg);
        transform: scale(1.3, 1.3) rotate(3deg);
    }
}


@media (min-width: 1200px) {
    .masonry-container {
        max-width: 1117px!important;
    }
}

















.footer-inverse {
    color: rgba(0,0,0,0.8);
}
.footer-inverse .footer-bottom {
    background: #f9f1eb;
}
.footer-bottom {
    padding: 1.5rem 0;
}
.footer .footer-menu {
    padding: 0;
    margin: 0;
    list-style: none;
}
.footer .footer-menu>li {
    display: inline-block;
    margin-right: 1rem;
}
.footer-inverse .footer-menu>li>a {
    /*color: rgba(0,0,0,0.7);*/
}
.footer .footer-menu>li>a {
    display: inline-block;
    padding: 0.25rem 0.4rem;
    font-weight: 500;
    text-transform: uppercase;
}
.footer-bottom .copyright {
    font-size: 0.8rem;
}
.footer .copy-links {
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;
}
.footer .copy-links li {
    display: inline-block;
    margin-right: 10px;
    font-size: .75rem;
    color: #818a91;
}
.footer .copy-links li a {
    font-size: .75rem;
}
.social-media {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
}
.social-media>li {
    display: inline-block;
    font-size: 1rem;
}
.social-media.social-media--style-1-v4>li>a {
    font-size: 2rem;
    width: auto;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    margin-right: 1rem;
    background: transparent;
}
[class*="social-media--style-1"]>li>a {
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    background: transparent;
}
.social-media>li>a {
    display: block;
}
.footer-inverse .social-media>li>a>i {
    /*color: rgba(0,0,0,0.7);*/
}



@media (min-width: 992px) {
    .navbar-brand--centered {
        position: absolute;
        left: calc(~'50% - 125px');
    }
    .navbar .navbar-nav .nav-link {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 0;
        width: 132px;
        text-align: center;
    }
}




.icon-block--style-1-v5 {
    position: relative;
}
.icon-block--style-1-v5 .block-icon {
    display: block;
    margin-bottom: 1rem;
}
.icon-block--style-1-v5 .block-icon>i {
    font-size: 40px;
}
.strong-600 {
    font-weight: 600 !important;
}
.heading-6 {
    font-size: 1rem !important;
}
.heading {
    margin: 0 0 6px;
    padding: 0;
    text-transform: none;
    font-family: "Nunito",sans-serif;
    font-weight: 600;
    color: #111;
    line-height: 1.46;
}
.icon-block--style-1-v5 .block-content p {
    margin-top: 10px;
}


.heading-1 {
    color: @color-primary!important;
    font-weight: 400!important;
}

.heading-2 {
    text-align: center;
    text-transform: uppercase;
    color: #4b4947!important;
    font-weight: 400!important;
}

.lead {
    opacity:0.5!important;
    color: white!important;
}





.sticky.is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    /*height: 120px;*/
}
@media (min-width: 992px) {
    .sticky.is-sticky {
        height: 120px;
    }
}


.b-lazy {
        -webkit-transition: opacity 500ms ease-in-out;
           -moz-transition: opacity 500ms ease-in-out;
             -o-transition: opacity 500ms ease-in-out;
                transition: opacity 500ms ease-in-out;
                 max-width: 100%;
                   opacity: 0;
    }
.b-lazy.b-loaded {
    opacity: 1;
}











.image-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1117px;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.image-bg {
  background-position: center center;
  background-size: cover;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin: .25rem;
  width: calc(~"20% - .50rem");
}
.image-bg-placeholder {
  height: 100%;
  visibility: hidden;
  width: 100%;
}

@media (max-width: 480px) {
  .image,
  .image-fit,
  .image-bg,
  .image-svg {
    width: calc(~"100% - .50rem");
  }
}








.gallery-block{
  /*padding-bottom: 60px;
  padding-top: 60px;*/
}

.gallery-block .heading{
    margin-bottom: 50px;
    text-align: center;
}

.gallery-block .heading h2{
    font-weight: bold;
    font-size: 1.4rem;
    text-transform: uppercase;
}

.gallery-block.compact-gallery .item{
  overflow: hidden;
  margin-bottom: 0;
  background: black;
  opacity: 1;
}

.gallery-block.compact-gallery .item .image{
  transition: 0.8s ease;
}

.gallery-block.compact-gallery .item .info{
  position: relative;
    display: inline-block;
}

.gallery-block.compact-gallery .item .description{
  display: grid;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    font-size: 17px;
    line-height: 18px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    opacity: 1;
    color: #fff;
    transition: 0.8s ease;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.39));
}

.gallery-block.compact-gallery .item .description .description-heading{
  font-size: 1em;
  font-weight: bold;
}

.gallery-block.compact-gallery .item .description .description-body{
  font-size: 0.8em;
  margin-top: 10px;
  font-weight: 300;
}

@media (min-width: 576px) {

  .gallery-block.compact-gallery .item .description {
    opacity: 0; 
  }

  .gallery-block.compact-gallery .item a:hover .description {
    opacity: 1; 
  } 

  .gallery-block .zoom-on-hover:hover .image {
    transform: scale(1.3);
    opacity: 0.7; 
  }
}




.insta-background {
    padding: 35px 0!important;
    background: @color-primary;
    background: -moz-linear-gradient(top, @color-primary 0%, rgba(74,72,70,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, @color-primary), color-stop(100%, rgba(74,72,70,1)));
    background: -webkit-linear-gradient(top, @color-primary 0%, rgba(74,72,70,1) 100%);
    background: -o-linear-gradient(top, @color-primary 0%, rgba(74,72,70,1) 100%);
    background: -ms-linear-gradient(top, @color-primary 0%, rgba(74,72,70,1) 100%);
    background: linear-gradient(to bottom, @color-primary 0%, rgba(74,72,70,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@color-primary', endColorstr='#4a4846', GradientType=0 );
}



